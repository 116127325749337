<template>
    <div
        v-if="supplier"
        v-resize="onResize"
        class="fill-height"
        ref="container"
    >
        <v-tabs
            ref="tabs"
            active-class="white"
            background-color="grey lighten-3"
            hide-slider
            show-arrows
        >
            <v-tab
                :to="{ name: 'supplier-general-info', params: { supplier } }"
            >
                General Information
            </v-tab>
        </v-tabs>
        <v-card :loading="loading" flat>
            <v-card-title class="py-2" ref="title">
                <v-btn
                    fab
                    depressed
                    @click="show"
                    icon
                    v-if="$vuetify.breakpoint.smAndDown"
                >
                    <v-icon size="36">
                        mdi-chevron-left
                    </v-icon>
                </v-btn>
                <v-avatar :aspect-ratio="4 / 3" size="47" class="mx-2">
                    <v-img
                        contain
                        :aspect-ratio="4 / 3"
                        v-if="supplier.profilePicURL"
                        :src="supplier.profilePicURL"
                        :alt="supplier.name"
                    />
                    <v-btn
                        v-else
                        class="mx-2 supplierIcon"
                        fab
                        dark
                        :plain="true"
                        large
                        color="grey darken-1"
                    >
                        <v-icon rounded large>mdi-office-building</v-icon>
                    </v-btn>
                </v-avatar>
                <span>{{ supplier.name }}</span>
                <v-spacer />
                <v-switch
                    v-if="supplier"
                    v-model="supplier.enabled"
                    color="primary"
                    class="my-0 py-0 mr-4"
                    hide-details
                    inset
                    :loading="loading"
                    @change="toggleSupplier"
                />
                <v-menu rounded offset-y>
                    <template v-slot:activator="{ attrs, on }">
                        <v-btn
                            fab
                            v-bind="attrs"
                            v-on="on"
                            small
                            color="grey lighten-3"
                            depressed
                            class="mx-1"
                        >
                            <v-icon>mdi-dots-horizontal</v-icon>
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item @click="openDialog">
                            <v-list-item-title>
                                Update Supplier</v-list-item-title
                            >
                        </v-list-item>
                        <v-dialog
                            :retain-focus="false"
                            v-model="deleteDialog"
                            persistent
                            max-width="400px"
                        >
                            <v-card :loading="loading">
                                <v-card-title class="text-h5"
                                    >Delete Supplier</v-card-title
                                >
                                <v-card-text>
                                    Are you sure you want to delete this
                                    supplier?
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        text
                                        color="secondary"
                                        @click="deleteDialog = false"
                                    >
                                        Close
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        @click="deleteSupplier"
                                        text
                                        color="error"
                                        :loading="loading"
                                    >
                                        Delete
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-list-item
                            :disabled="!permDelete || supplier.enabled"
                            @click.stop="deleteDialog = true"
                        >
                            <v-list-item-title>
                                Delete Supplier
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>

                <SupplierForm
                    v-if="supplierDialog"
                    v-model="supplierDialog"
                    title="Supplier Information"
                    :create-form="false"
                    @closeDialog="closeDialog"
                    :supplier="this.supplierToUpdate"
                    :originalSupplier="this.originalSupplier"
                    @replaceSupplier="this.replaceSupplier"
                />
            </v-card-title>

            <v-card-subtitle ref="subtitle" class="mt-0">
                <v-divider />
            </v-card-subtitle>

            <v-card-text class="overflow-y-auto" :style="`height: ${height}px`">
                <router-view />
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import SupplierForm from '@/components/Suppliers/SupplierForm'
import { mapMutations } from 'vuex'
import _ from 'lodash'
import API from '@/services/api'
import { storage } from '@/services/firebase'
export default {
    name: 'Supplier',
    components: {
        SupplierForm,
    },
    props: {
        id: String,
        supplier: Object,
    },
    data() {
        return {
            height: 0,
            supplierDialog: false,
            supplierToUpdate: null,
            permDelete: false,
            originalSupplier: null,
            user: JSON.parse(localStorage.getItem('userId')),
            companyId: JSON.parse(localStorage.getItem('company')),
            folderPictures: 'suppliers_pictures',
            deleteDialog: false,
            loading: false,
            deleteError: false,
            error: false,
            errorMsg: null,
        }
    },
    inject: {
        removeSupplier: {
            from: 'removeSupplier',
            default() {
                return null
            },
        },
        closeDetail: {
            from: 'closeDetail',
            default() {
                return null
            },
        },
    },
    updated() {
        this.onResize()
    },
    computed: {
        dinamicInfo: function() {
            return this.supplier
        },
    },
    async created() {
        const {
            data: { user },
        } = await API.getMyInfo()
        const deleteSuppliers = user.permissions.find(
            x => x == 'deleteSupplier'
        )
        if (deleteSuppliers) {
            this.permDelete = true
        }
    },
    watch: {
        dinamicInfo: {
            deep: true,
            handler: function(supplier) {
                this.supplier = supplier
            },
        },
        supplier: async function() {
            const {
                data: { user },
            } = await API.getMyInfo()
            const deleteSuppliers = user.permissions.find(
                x => x == 'deleteSuppliers'
            )
            if (deleteSuppliers) {
                this.permDelete = true
            }
        },
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        onResize() {
            const {
                container: { clientHeight: containerHeight },
                tabs: {
                    $el: { clientHeight: tabsHeight },
                },
                title: { clientHeight: titleHeight },
                subtitle: { clientHeight: subtitleHeight },
            } = this.$refs
            this.height =
                containerHeight - tabsHeight - titleHeight - subtitleHeight
        },
        show() {
            if (this.$vuetify.breakpoint.smAndDown) {
                this.$emit('show')
            }
            this.closeDetail(false)
        },
        openDialog: function() {
            this.supplierToUpdate = _.cloneDeep(this.supplier)
            this.originalSupplier = _.cloneDeep(this.supplier)
            this.supplierDialog = true
        },
        closeDialog: function() {
            this.supplierDialog = false
        },
        deleteDocument: async function(doc) {
            const path = `${this.companyId}/suppliers_documents/${this.supplier.id}`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(doc)
            await refDoc.delete()
            return true
        },
        async deleteSupplierContacts(contacts) {
            for (const contact of contacts) {
                await API.deleteContact(contact.id)
            }
        },
        deleteSupplierPicture: async function(picture) {
            const path = `${this.companyId}/suppliers_pictures`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(picture)
            await refDoc.delete()
            return true
        },
        deleteQuoteDocuments: async function(doc, id) {
            const path = `${this.companyId}/quotes_documents/${id}`
            const storageRef = storage().ref(path)
            const refDoc = storageRef.child(doc)
            await refDoc.delete()
            return true
        },
        deleteSupplierQuotes: async function(quotes) {
            quotes.forEach(quote => {
                if (quote.files.length > 0) {
                    quote.files.forEach(doc => {
                        this.deleteQuoteDocuments(doc.file, quote.id)
                    })
                }
                API.deleteQuote(quote.id)
            })
        },
        deleteSupplier: async function() {
            try {
                this.loading = true
                this.deleteError = false
                this.errorMsg = null
                const { id } = this.supplier
                if (this.supplier.contacts) {
                    await this.deleteSupplierContacts(this.supplier.contacts)
                }
                if (this.supplier.picture) {
                    await this.deleteSupplierPicture(this.supplier.picture)
                }
                await API.deleteSupplier(id)
                this.removeSupplier(id)
                this.$router.push('/suppliers')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
                this.deleteDialog = false
            }
        },
        replaceSupplier: function(id, supplier) {
            this.supplier.id = id
            this.supplier.name = supplier.name
            this.supplier.profilePicURL = supplier.profilePicURL
            this.supplier.city = supplier.city
            this.supplier.currency = supplier.currency
            this.supplier.currency2 = supplier.currency2
            this.supplier.language = supplier.language
            this.supplier.country = supplier.country
            this.supplier.state = supplier.state
            this.supplier.nit = supplier.nit
            this.supplier.note = supplier.note
        },
        toggleSupplier: async function() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                await API.updateSupplier(this.supplier.id, {
                    enabled: this.supplier.enabled,
                })
            } catch (error) {
                this.user.enabled = !this.user.enabled
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>
<style scoped>
.supplierIcon {
    cursor: default;
}
</style>
